import * as React from 'react';

import { Layout } from '../components/layout';

const NotFoundPage = () => (
  <Layout seo={{ metaTitle: '404: Not found' }}>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
